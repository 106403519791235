<template>
	<div>
		<div class="page-section">
			<div class="page-section__inner gr gr--wrap">
				<div class="col col--1@tablet hide show@tablet"></div>
				<div class="col col--12 col--10@tablet">
					<VHeading level="2" classes="page-section__card__title space--big">
						Reset Your Password
					</VHeading>
					<div class="page-section__card space--big">
						<div class="page-section__card__block__left no--padding-b">
							<form class="form__column" @submit.prevent="submit">
								<VDefaultField :error="errors.get('password')">
									<template #field>
										<VInput
											v-model="form.password"
											type="password"
											placeholder="Password"
											:error="errors.get('password')"
											@input="errors.clear('password')"
										/>
									</template>
								</VDefaultField>

								<VDefaultField :error="errors.get('password2')">
									<template #field>
										<VInput
											v-model="form.password2"
											type="password"
											placeholder="Confirm password"
											:error="errors.get('password2')"
											@input="errors.clear('password2')"
										/>
									</template>
								</VDefaultField>

								<VButton color="primary" type="submit">
									Change Password
								</VButton>
							</form>
						</div>
					</div>
				</div>
				<div class="col col--1@tablet hide show@tablet"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { CHANGE_PASSWORD, SET_AUTH_ERROR } from '@/store/actions.type';
import { OPEN_ALERT, SET_ACTIVE_MODAL } from '@/store/mutations.type';

export default {
	name: 'ResetPasswordPage',
	data() {
		return {
			uid: 'NQ',
			token:
				'VDlLO3dUvTFWiW9lG8QRImhPCOLu3yhHs1s6Fj3t2TbFsbY40ZBkvFLj0VEXD5-2BA6HTG-2BDb9Sg0-2FqA6wZ-2BkyNRcYTu3eOKJmgyy-2FBZfUf3HGa5v0c0Pu7NcbjlhztlfHO51mfo215Iy3y2n6El8ExQ-3D-3DZ9jR_RfBFlLemAtvDh7VvH-2FtyNaONN7TfEYVq9FO4yLp9giR8slpwtAhtAjEgOo34Jz5zMxEtQreZGPHJuKDkYvnT1wnU2ew6SckQZYZWea9FDtap5rOlHzfSzx6oZKKDm30DobIN-2BawFuJHu-2F6c-2FV79-2B2W56jo7EM8io2oiIZyL2rBgjW1DlQtfU6814YRxYYpFdN1foNe-2F4uu0wcA7BhCmkpNPWV-2B2ttZrLal9mhOT4NVw-3D',
			form: {
				password: '',
				password2: ''
			}
		};
	},
	computed: {
		...mapState({
			errors: state => state.auth.errors
		})
	},
	methods: {
		async submit() {
			this.checkForm();

			if (this.errors.hasErrors()) return;

			await this.$store.dispatch(CHANGE_PASSWORD, {
				uid: this.uid,
				token: this.token,
				form: this.form
			});

			this.$store.commit(SET_ACTIVE_MODAL, null);
			this.$store.commit(OPEN_ALERT, {
				type: 'success',
				title: 'Success',
				content: 'Successfully change password'
			});
		},
		checkForm: function(e) {
			let errors = {};

			if (this.form.password !== this.form.password2) {
				errors.password = ['Passwords must match.'];
			}

			if (!this.form.password) {
				errors.password = ['Password is required'];
			}

			if (!this.form.password2) {
				errors.password2 = ['Password Confirmation is required'];
			}

			this.$store.dispatch(SET_AUTH_ERROR, errors);
		}
	}
};
</script>
